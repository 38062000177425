import React from 'react';
import AppDrawer from './AppDrawer'
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Vatomic from '../../Vatomic';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { FormControl } from '../../../node_modules/@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ResourcesDialog from '../Dialogs/ResourcesDialog';
import AutoCompleteSelect from 'react-select/lib/Creatable';





export default class Templates extends React.Component {
    constructor() {
        super();
        this.state = {
            user: "",
            open: false,
            resourceDialog: false,
            template: { name: "" },
            changes: {},
            redeemable: false,
            updateDialog: false,
            redeemableProps: {
                org_change: {
                    redeemable: false
                },
                return_change: {},
                return_name: "",
                valid_from: "0001-01-01T00:00:00Z",
                valid_until: "0001-01-01T00:00:00Z"
            }
        }
    }

    componentWillMount() {
        if (!Vatomic.isLoggedin()) {
            this.props.history.push('/');
        }
        if (typeof (this.props.location.state) !== 'undefined') {
            this.setState({ user: this.props.location.state.user })
            this.setState({ open: this.props.location.state.open || false })
        }
    }

    componentDidMount() {
        Vatomic.templates().then(templates => {
            templates = templates.sort((template1, template2) => {
                return (template1.name > template2.name ? -1 : template1.name < template2.name) * -1;
            })
            this.setState({
                templates: templates
            })
        })
        Vatomic.templateVariations().then(variations => {
            this.setState({
                variations: variations
            })
        })
        var template = this.state.template
        template.redeemable = this.state.redeemableProps
        this.setState({ template: template })
    }

    logout() {
        localStorage.clear();
        this.props.history.push("/")
    }

    handlePublishChange(event) {
        var template = this.state.template
        template.properties.unpublished = !event.target.checked
        this.setState({ template: template })
    }

    handleRedeemableChange(event) {
        this.setState({ redeemable: event.target.checked })
        if (!event.target.checked) {
            var props = {
                org_change: {
                    redeemable: false
                },
                return_change: {},
                return_name: "",
                valid_from: "",
                valid_until: ""
            }
            var template = this.state.template
            delete template.redeemable
            template.properties.template["vAtom::vAtomType"].redeemable = false
            this.setState({ redeemableProps: props })
            this.setState({ template: template })
        }
        else {
            var template = this.state.template
            template.properties.template["vAtom::vAtomType"].redeemable = true
            this.setState({ template: template })
        }
    }

    handlePpropChange(event) {
        var pprop = event.target.name;
        var template = this.state.template;
        var changes = this.state.changes;
        changes[pprop] = true
        template.properties.template.private[pprop] = event.target.value;
        this.setState(template);
        this.setState(changes);
    }
    handleGpropChange(event) {

        var gprop = event.target.name;
        var template = this.state.template;

        if (gprop === "template_variation") {
            template[gprop] = event.target.value;
        }
        else {
            template.properties.template['vAtom::vAtomType'][gprop] = event.target.value;
        }
        this.setState({ template: template });
    }

    handleRpropChange(event) {
        var rprop = event.target.name;
        var rprops = this.state.redeemableProps;
        rprops[rprop] = event.target.value;
        var template = this.state.template
        template.redeemable = rprops
        this.setState({ redeemableProps: rprops });
        this.setState({ template: template });
    }

    handleSelectChange(event) {
        // console.log(this.state.variations)
        var searching_variations = Object.assign([],this.state.variations.filter(variation=>variation.name.includes(event.target.value.name)))
        this.setState({ template: event.target.value, searchingVariations : searching_variations });
        event.target.value.properties.template["vAtom::vAtomType"].resources.map(resource=>{
            this.setState({[resource.name]:resource.value.value})
        })
    }

    handleDrawerOpen() {
        this.setState({ open: true });
    };

    handleDrawerClose() {
        this.setState({ open: false });
    };

    handleDrawerClick(event) {
        var location = {
            pathname: '/' + event.target.id,
            state: { open: this.state.open }
        }
        this.props.history.push(location);
    }

    handleAutoCompleteSelect(value){
        var templateVariations = this.state.searchingVariations.filter(variation=>variation.name === value.value)
        if(templateVariations.length === 0 ){
            var template = this.state.template;
            template["template_variation"] = value.value
            this.setState({template:template})
        }
        else{
            var templateVariation = Object.assign({},JSON.parse(JSON.stringify(templateVariations[0])))
            templateVariation.properties.template = templateVariation.properties.template_variation
            templateVariation.name = this.state.template.name
            templateVariation.template_variation = value.value
            delete templateVariation.properties.template_variation
            templateVariation.properties.template["vAtom::vAtomType"].resources.map(resource=>
                this.setState({[resource.name]:resource.value.value})
            )
            this.setState({template:templateVariation})
        }
        
    }

    handleResourceDialogClose(event) {
        if (event.target.id === 'overlay')
            this.setState({ resourceDialog: false })
    }
    handleResourceDialogOpen(event) {
        this.setState({ resourceType: event.target.name.split('::')[2] })
        this.setState({ resourceDialog: true })
        this.setState({ dialogTrigger: event.target.id })
    }

    handleDialogClick(event) {
        var template = this.state.template

        template.properties.template['vAtom::vAtomType'].resources.map(resource => {
            if (resource.name === this.state.dialogTrigger) {
                resource.value.value = event.target.name
            }
        })
        this.setState({ template: template })
        this.setState({ [this.state.dialogTrigger]: event.target.name })
        this.setState({ resourceDialog: false })
    }

    createVariation() {
        Vatomic.createTemplateVariation(Vatomic.encodeTemplateVariationCreate(this.state.template)).then(result => {
            if (typeof (result) === 'number' && result === 409)
                this.setState({ updateDialog: true })
            else {
                if (typeof (result) === 'number')
                    this.setState({ result: 'Error: ' + result })
                else
                    this.setState({ result: result.success_message })
            }
        })
    }

    handleUpdateClose() {
        this.setState({ updateDialog: false })
    }

    handleResultClose() {
        // this.setState({result:null})
        // if(typeof(this.state.result)==='number')
        //     this.setState({result:null})
        // else
            window.location.reload()
    }

    handleUpdate() {
        Vatomic.updateTemplateVariation(Vatomic.encodeTemplateVariationCreate(this.state.template)).then(result => {
            if (typeof (result) === 'number')
                this.setState({ result: 'Error: ' + result })
            else
                this.setState({ result: result.success_message })
        })
    }
    
    handleResourceChange(event){
        this.setState({[event.target.id]:event.target.value})
    }

    render() {
        var component = <div>
            {this.state.templates ?
                <div style={{ textAlign: 'center', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="template-selector">Template</InputLabel>
                        <Select
                            value={this.state.template.name}
                            onChange={this.handleSelectChange.bind(this)}
                            input={<Input id="template-selector" />}
                            renderValue={value => value}
                            style={{ width: '80%' }}
                        >
                            {this.state.templates.map(template => {
                                return <MenuItem key={template.name} value={template}>
                                    {template.name}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl></div> : null}

            {this.state.template.properties ?

                <div style={{ textAlign: 'left', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Divider style={{ marginTop: 20 }} />
                    <p style={{ marginBottom: 0 }}>General Properties</p>
                    <AutoCompleteSelect
                        options={this.state.searchingVariations.map(variation => ({ value: variation.name, label: variation.name }))}
                        onChange={this.handleAutoCompleteSelect.bind(this)}
                        className="custom-select"
                        placeholder="Type to search variation"
                    />
                    <TextField
                        name="title"
                        style={{ marginRight: 10 }}
                        label="title"
                        value={this.state.template.properties.template['vAtom::vAtomType'].title}
                        onChange={this.handleGpropChange.bind(this)}
                        margin="none"
                        InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                    />
                    <TextField
                        name="description"
                        style={{ marginRight: 10 }}
                        label="description"
                        value={this.state.template.properties.template['vAtom::vAtomType'].description}
                        onChange={this.handleGpropChange.bind(this)}
                        margin="none"
                        InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                    />
                    <TextField
                        name="category"
                        style={{ marginRight: 10 }}
                        label="category"
                        value={this.state.template.properties.template['vAtom::vAtomType'].category}
                        onChange={this.handleGpropChange.bind(this)}
                        margin="none"
                        InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!this.state.template.properties.unpublished}
                                onChange={this.handlePublishChange.bind(this)}
                                color="primary"
                            />
                        }
                        label="Published"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.redemable}
                                onChange={this.handleRedeemableChange.bind(this)}
                                color="primary"
                            />
                        }
                        label="Redeemable"
                    />

                    <Divider style={{ marginTop: 20 }} />
                    <p style={{ marginBottom: 0 }}>Private Properties</p>
                    {Object.keys(this.state.template.properties.template.private).map(pprop => {
                        return (
                            typeof(this.state.template.properties.template.private[pprop]) !== "boolean"?
                                <TextField
                                    key={pprop}
                                    name={pprop}
                                    style={{ marginRight: 10 }}
                                    label={pprop}
                                    value={this.state.template.properties.template.private[pprop]}
                                    onChange={this.handlePpropChange.bind(this)}
                                    margin="none"
                                    InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                                /> 
                                : null
                                )})}
                    <Divider style={{ marginTop: 20 }} />
                    <p style={{ marginBottom: 0 }}>Resources</p>

                    {this.state.template.properties.template['vAtom::vAtomType'].resources.map(resource => {
                        return (
                            <div key={resource.name} style={{display:'inline-block'}}>
                                <TextField
                                    name={resource.resourceType}
                                    id={resource.name}
                                    style={{ marginRight: 10 }}
                                    label={resource.name}
                                    helperText={resource.resourceType}
                                    onFocus={this.handleResourceDialogOpen.bind(this)}
                                    value={this.state[resource.name]}
                                    onChange={this.handleResourceChange.bind(this)}
                                    margin="none"
                                    InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                                />
                                {this.state[resource.name] && !resource.resourceType.includes('.mp4')?
                                    <img alt={resource.name} style={{width:80,display: 'block',marginTop: 10}} src={Vatomic.encodeAssetProvider(this.state[resource.name])}/>
                                :null}
                            </div>
                        )
                    })}

                    {this.state.redeemable ?
                        <div>
                            <Divider style={{ marginTop: 20 }} />
                            <p style={{ marginBottom: 0 }}>Redeemable Properties</p>
                            {Object.keys(this.state.redeemableProps).map(prop => {
                                return (
                                    prop !== "org_change" && prop !== 'return_change' ?
                                        <TextField
                                            key={prop}
                                            name={prop}
                                            style={{ marginRight: 10 }}
                                            label={prop}
                                            value={this.state.redeemableProps[prop]}
                                            onChange={this.handleRpropChange.bind(this)}
                                            margin="none"
                                            InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                                        />
                                        : null
                                )
                            })}
                        </div>
                        : null
                    }
                    <Divider style={{ marginTop: 20 }} />
                    <ExpansionPanel style={{ marginTop: 20 }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Preview</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {/* <Typography> */}
                            <pre style={{ overflow: 'auto' }}>{JSON.stringify(Vatomic.encodeTemplateVariationCreate(this.state.template), undefined, 4)}</pre>
                            {/* </Typography> */}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Button color="primary" className="btn-full" style={{ width: '100%', height: '48px', color: "#fff", marginTop: 10 }} variant="contained" onClick={this.createVariation.bind(this)}>
                        Create Variation
                    </Button>
                </div> : null}


            {this.state.resourceDialog ?
                <ResourcesDialog
                    resourceType={this.state.resourceType}
                    handleClick={this.handleDialogClick.bind(this)}
                    onClose={this.handleResourceDialogClose.bind(this)} />
                : null}

            <Dialog
                open={this.state.updateDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Variation already exists"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to update instead?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleUpdateClose.bind(this)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleUpdate.bind(this)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={Boolean(this.state.result)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Variation Creation Result"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.result}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleResultClose.bind(this)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
        return (
            <AppDrawer component={component}
                title="Create new Variation"
                logout={this.logout.bind(this)}
                history={this.props.history}
                location={this.props.location}
                handleDrawerClick={this.handleDrawerClick.bind(this)}
                handleDrawerClose={this.handleDrawerClose.bind(this)}
                handleDrawerOpen={this.handleDrawerOpen.bind(this)}
                open={this.state.open}
            />
        );



    }

}