// var appId = '06210c5c-d9d8-49f2-bfb4-01dc43feab62'; // infonavit
var appId = '986d3160-aa85-44bf-baed-fa04e072b10b'; // jalo

var serverAddress = 'https://api.blockv.io/v1/';
// var publisher_fqdn = "blockv.infonavit";
export default class Vatomic {

    static login(user, password) {
        var headers = {
            'Content-type': 'application/json',
            'App-Id': appId
        }

        var payload = {
            "token": user,
            "token_type": "email",
            "auth_data": {
                "password": password
            }
        }

        return this.request('post', 'user/login', headers, payload).then(result => {
            if (typeof (result) !== 'number') {

                if (result.payload.user.system_properties.is_admin) {
                    localStorage.setItem('access_token', result.payload.access_token.token)
                    localStorage.setItem('refresh_token', result.payload.refresh_token.token)
                    localStorage.setItem('ap', JSON.stringify(result.payload.asset_provider[0].descriptor))
                    localStorage.setItem('pub_fqdn', result.payload.user.system_properties.pub_fqdn)
                } else {
                    return 17
                }
            }
            return result
        })
    }

    static request(method, endpoint, headers, payload) {
        if (method !== 'get') {
            return fetch(serverAddress + endpoint, {
                method: method,
                headers: headers,
                body: JSON.stringify(payload)
            }).then((response) => {
                if (!response.ok) {
                    return response.status
                }
                return response.json()
            }).then(result => {
                return result
            })
        }
        else {
            return fetch(serverAddress + endpoint, {
                method: method,
                headers: headers
            }).then((response) => {
                if (!response.ok) {
                    return response.status
                }
                return response.json()
            }).then(result => {
                return result
            })
        }
    }

    static encodeAssetProvider(url) {
        var aP = JSON.parse(localStorage.getItem("ap"));
        var queryString = this.mapString(aP);
        return url + '?' + queryString;
    }

    static mapString(o) {
        return Object.keys(o).map(function (key) {
            return key + '=' + o[key];
        }).join('&');
    }

    static isLoggedin() {
        if (localStorage.getItem('refresh_token') == null)
            return false

        var exp_date = this.parseJwt(localStorage.getItem('refresh_token')).exp
        if (Date.now() > exp_date * 1000)
            return false

        return true
    }

    static parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    };

    static refreshToken() {
        var temp_heads = {
            'Content-type': 'application/json',
            'App-Id': appId,
            'Authorization': 'Bearer ' + localStorage.getItem('refresh_token')
        }
        return this.request('post', 'access_token', temp_heads, {}).then(result => {
            localStorage.setItem('access_token', result.payload.access_token.token)
            return result.payload.access_token.token
        })

    }

    static templates(link) {
        link = link || "templates?page[size]=50";
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }
            return this.request('get', link, headers, {}).then(result => {
                if ('next' in result.links) {
                    return this.templates(result.links.next.replace("/v1/", "")).then(resultados => {
                        return result.payload.concat(resultados)
                    })
                } else {
                    return result.payload;
                }
            })
        } else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }
                return this.request('get', link, headers, {}).then(result => {
                    if ('next' in result.links) {
                        return this.templates(result.links.next.replace("/v1/", "")).then(resultados => {
                            return result.payload.concat(resultados)
                        })
                    } else {
                        return result.payload;
                    }
                })
            })
        }

    }

    static templateVariations(link) {
        link = link || "template_variations?page[size]=50";
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            return this.request('get', link, headers, {}).then(result => {
                if ('next' in result.links) {
                    return this.templateVariations(result.links.next.replace("/v1/", "")).then(resultados => {
                        return result.payload.concat(resultados)
                    })
                } else {
                    return result.payload;
                }

            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }
                return this.request('get', link, headers, {}).then(result => {
                    if ('next' in result.links) {
                        return this.templates(result.links.next.replace("/v1/", "")).then(resultados => {
                            return result.payload.concat(resultados)
                        })
                    } else {
                        return result.payload;
                    }
                })
            })
        }

    }

    static assets() {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            return this.request('get', "publishers/" + localStorage.getItem('pub_fqdn') + "/assets", headers, {}).then(result => {
                return result.payload
            })
        } else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                return this.request('get', "publishers/" + localStorage.getItem('pub_fqdn') + "/assets", headers, {}).then(result => {
                    return result.payload
                })
            })
        }
    }

    static uploadAsset(file) {
        var formdata = new FormData();
        formdata.append("prefix", "");
        formdata.append("upload[]", file);
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            return fetch("https://api.blockv.io/v1/publishers/" + localStorage.getItem("pub_fqdn") + "/assets", {
                method: 'post',
                headers: headers,
                body: formdata
            }).then((response) => {
                if (!response.ok) {
                    return response.status
                }
                return response.json()
            }).then(result => {
                return result
            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                return fetch("https://api.blockv.io/v1/publishers/" + localStorage.getItem("pub_fqdn") + "/assets", {
                    method: 'post',
                    headers: headers,
                    body: formdata
                }).then((response) => {
                    if (!response.ok) {
                        return response.status
                    }
                    return response.json()
                }).then(result => {
                    return result
                })
            })
        }
    }

    static encodeTemplateVariation(variation) {
        var new_variation = Object.assign({}, JSON.parse(JSON.stringify(variation)));
        delete new_variation.meta
        Object.keys(new_variation.properties).map(prop => {
            new_variation[prop] = new_variation.properties[prop]
        })
        delete new_variation.properties
        new_variation["vatom"] = new_variation.template_variation
        delete new_variation.template_variation
        delete new_variation.vatom.version
        delete new_variation.vatom["vAtom::vAtomType"].template_variation
        delete new_variation.vatom["vAtom::vAtomType"].template
        new_variation.template_variation = new_variation.name
        delete new_variation.name
        delete new_variation.vatom["vAtom::vAtomType"].author
        delete new_variation.vatom["vAtom::vAtomType"].disabled
        delete new_variation.vatom["vAtom::vAtomType"].dropped
        delete new_variation.vatom["vAtom::vAtomType"].geo_pos
        delete new_variation.vatom["vAtom::vAtomType"].in_contract
        delete new_variation.vatom["vAtom::vAtomType"].in_contract_with
        delete new_variation.vatom["vAtom::vAtomType"].num_direct_clones
        delete new_variation.vatom["vAtom::vAtomType"].owner
        delete new_variation.vatom["vAtom::vAtomType"].parent_id
        delete new_variation.vatom["vAtom::vAtomType"].cloned_from
        delete new_variation.vatom["vAtom::vAtomType"].tradeable
        delete new_variation.vatom["vAtom::vAtomType"].location
        delete new_variation.vatom["vAtom::vAtomType"].transferred_by
        delete new_variation.vatom["vAtom::vAtomType"].root_type
        delete new_variation.publisher_fqdn
        delete new_variation.vatom["vAtom::vAtomType"].publisher_fqdn

        return new_variation
    }
    static encodeTemplateVariationCreate(variation) {
        var new_variation = Object.assign({}, variation);
        delete new_variation.meta
        Object.keys(new_variation.properties).map(prop => {
            new_variation[prop] = new_variation.properties[prop]
        })
        delete new_variation.properties
        new_variation["vatom"] = new_variation.template
        delete new_variation.template
        delete new_variation.vatom.version
        delete new_variation.vatom["vAtom::vAtomType"].template_variation
        delete new_variation.vatom["vAtom::vAtomType"].template
        new_variation.template = new_variation.name
        delete new_variation.name
        delete new_variation.vatom["vAtom::vAtomType"].author
        delete new_variation.vatom["vAtom::vAtomType"].disabled
        delete new_variation.vatom["vAtom::vAtomType"].dropped
        delete new_variation.vatom["vAtom::vAtomType"].geo_pos
        delete new_variation.vatom["vAtom::vAtomType"].in_contract
        delete new_variation.vatom["vAtom::vAtomType"].in_contract_with
        delete new_variation.vatom["vAtom::vAtomType"].num_direct_clones
        delete new_variation.vatom["vAtom::vAtomType"].owner
        delete new_variation.vatom["vAtom::vAtomType"].parent_id
        delete new_variation.vatom["vAtom::vAtomType"].cloned_from
        delete new_variation.vatom["vAtom::vAtomType"].tradeable
        delete new_variation.vatom["vAtom::vAtomType"].transferred_by
        delete new_variation.vatom["vAtom::vAtomType"].root_type
        delete new_variation.vatom["vAtom::vAtomType"].location
        delete new_variation.publisher_fqdn
        delete new_variation.vatom["vAtom::vAtomType"].publisher_fqdn

        return new_variation
    }

    static emitVatom(template_variation) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            var payload = {
                template_variation: template_variation,
                num: 1
            }

            return this.request('post', 'vatoms', headers, payload).then(result => {
                return result.payload;
            })
        } else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                var payload = {
                    template_variation: template_variation,
                    num: 1
                }

                return this.request('post', 'vatoms', headers, payload).then(result => {
                    return result.payload;
                })
            })
        }
    }

    static sendVatom(recipient, template_variation) {
        return this.emitVatom(template_variation).then(result => {
            var token = ""
            var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
            if (Date.now() < exp_date * 1000) {
                token = localStorage.getItem('access_token')
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }
                var payload = {}
                if (recipient.indexOf('@') === -1) {
                    payload = {
                        "this.id": result.ids[0],
                        "new.owner.phone_number": recipient
                    }
                } else {
                    payload = {
                        "this.id": result.ids[0],
                        "new.owner.email": recipient
                    }
                }

                return this.request('post', 'user/vatom/action/Transfer', headers, payload).then(result => {
                    return result;
                })

            } else {
                return this.refreshToken().then(token => {

                    var headers = {
                        'Content-type': 'application/json',
                        'App-Id': appId,
                        'Authorization': 'Bearer ' + token
                    }
                    var payload = {}
                    if (recipient.indexOf('@') === -1) {
                        payload = {
                            "this.id": result.ids[0],
                            "new.owner.phone_number": recipient
                        }
                    } else {
                        payload = {
                            "this.id": result.ids[0],
                            "new.owner.email": recipient
                        }
                    }

                    return this.request('post', 'user/vatom/action/Transfer', headers, payload).then(result => {
                        return result;
                    })
                })

            }
        })
    }

    //metho called to emit and drop vatom coords have to be in form of lat,long
    static dropVatom(template_variation, coords) {
        return this.emitVatom(template_variation).then(result => {
            var token = ""
            var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
            if (Date.now() < exp_date * 1000) {
                token = localStorage.getItem('access_token')
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }
                var payload = {}
                if (coords.indexOf(',') === -1) {
                    return "Not Valid coords"
                }
                payload = {
                    "this.id": result.ids[0],
                    "geo.pos": {
                        "Lon": parseFloat(coords.split(",")[1]),
                        "Lat": parseFloat(coords.split(",")[0])
                    }
                }
                return this.request('post', 'user/vatom/action/Drop', headers, payload).then(result => {
                    return result;
                })

            } else {
                return this.refreshToken().then(token => {

                    var headers = {
                        'Content-type': 'application/json',
                        'App-Id': appId,
                        'Authorization': 'Bearer ' + token
                    }
                    var payload = {}
                    if (coords.indexOf(',') === -1) {
                        return "Not Valid coords"
                    }
                    payload = {
                        "this.id": result.ids[0],
                        "geo.pos": {
                            "Lon": parseFloat(coords.split(",")[1]),
                            "Lat": parseFloat(coords.split(",")[0])
                        }
                    }

                    return this.request('post', 'user/vatom/action/Transfer', headers, payload).then(result => {
                        return result;
                    })
                })

            }
        })
    }

    static deleteAsset(url) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            var payload = {
                urls: [
                    url
                ]
            }

            return this.request('delete', 'publishers/' + localStorage.getItem("pub_fqdn") + '/assets', headers, payload).then(result => {
                return result.payload;
            })
        } else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                var payload = {
                    urls: [
                        url
                    ]
                }

                return this.request('delete', 'publishers/' + localStorage.getItem("pub_fqdn") + '/assets', headers, payload).then(result => {
                    return result.payload;
                })
            })
        }
    }

    static createTemplate(template) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            var payload = template

            return this.request('post', 'templates', headers, payload).then(result => {
                return result.payload;
            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                var payload = template

                return this.request('post', 'templates', headers, payload).then(result => {
                    return result.payload;
                })
            })
        }
    }

    static createTemplateVariation(variation) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            var payload = variation

            return this.request('post', 'template_variations', headers, payload).then(result => {
                if (typeof (result) === 'number')
                    return result
                return result.payload;
            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                var payload = variation

                return this.request('post', 'template_variations', headers, payload).then(result => {
                    if (typeof (result) === 'number')
                        return result
                    return result.payload;
                })
            })
        }
    }

    static updateTemplateVariation(variation) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            var payload = variation

            return this.request('patch', 'template_variations/' + variation.template_variation, headers, payload).then(result => {
                if (typeof (result) === 'number')
                    return result
                return result.payload;
            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                var payload = variation

                return this.request('patch', 'template_variations/' + variation.template_variation, headers, payload).then(result => {
                    if (typeof (result) === 'number')
                        return result
                    return result.payload;
                })
            })
        }
    }

    static listActions(template) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            return this.request('get', 'user/actions/' + template, headers).then(result => {
                if (typeof (result) === 'number')
                    return result
                return result.payload;
            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                return this.request('get', 'user/actions' + template, headers).then(result => {
                    if (typeof (result) === 'number')
                        return result
                    return result.payload;
                })
            })
        }
    }

    static getAction(action) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            return this.request('get', 'actions/' + action, headers).then(result => {
                if (typeof (result) === 'number')
                    return result
                return result.payload;
            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                return this.request('get', 'user/actions' + action, headers).then(result => {
                    if (typeof (result) === 'number')
                        return result
                    return result.payload;
                })
            })
        }
    }

    static listFaces(template) {
        var token = ""
        var exp_date = this.parseJwt(localStorage.getItem('access_token')).exp
        if (Date.now() < exp_date * 1000) {
            token = localStorage.getItem('access_token')
            var headers = {
                'Content-type': 'application/json',
                'App-Id': appId,
                'Authorization': 'Bearer ' + token
            }

            return this.request('get', 'publisher/faces/' + template, headers).then(result => {
                if (typeof (result) === 'number')
                    return result
                return result.payload;
            })
        }
        else {
            return this.refreshToken().then(token => {
                var headers = {
                    'Content-type': 'application/json',
                    'App-Id': appId,
                    'Authorization': 'Bearer ' + token
                }

                return this.request('get', 'publisher/faces' + template, headers).then(result => {
                    if (typeof (result) === 'number')
                        return result
                    return result.payload;
                })
            })
        }
    }



}